import React from 'react';
import PropTypes from 'prop-types';
import generalFailedPC from '../../fetch/generalFailedPC';
import Tooltip from '../../general/Tooltip';
import FormModal from '../../fetch/FormModal';
import generalNoParameterFormPC from '../../fetch/generalNoParameterFormPC';
import {createFetchProductRemoveRelative} from '../../../backend/apiCalls';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';
import { ProductRelative } from '../../../constants/propTypesDefinitions';
import { formatProductName } from '../../../lib/formatting';
/**
 * @fero
 */

class RemoveRelatedProduct extends React.PureComponent {
    static propTypes = {
        onFinish: PropTypes.func.isRequired,
        relatedProduct: ProductRelative.isRequired,
        disabled: PropTypes.bool,
    };

    render() {
        const {relatedProduct, onFinish, disabled, ...props} = this.props;
        return <FormModal
            {...props}
            disabled={disabled}
            openNode={
                <Tooltip title={<Trans>Odstrániť prepojenie</Trans>}>
                    <Button disabled={disabled} size="small" type="danger" icon="delete"/>
                </Tooltip>
            }
            values={{}}
            onFinishSuccessful={onFinish}
            title={<span>
                <Trans>Odstrániť prepojenie s</Trans>
                {' '}
                {formatProductName(relatedProduct.designation, relatedProduct.manufacturer)}
            </span>}
            Form={generalNoParameterFormPC(
                <Trans>Naozaj chcete odstrániť prepojenie medzi produktami?</Trans>,
                createFetchProductRemoveRelative(
                    relatedProduct.id_product, 
                    relatedProduct.id_relative,
                ),
            )}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa odstrániť prepojenie.`)}
        />;
    }

}

export default RemoveRelatedProduct;